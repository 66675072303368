import { useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Table,
  Typography,
  message,
} from 'antd';
import axios from 'axios';

import Logo from '../../assets/images/logo.png';

import './ProductRegistration.scss';
import CSRFToken from '../../utils/cookies';

export const ProductRegistration = () => {
  const [canSendPromotions, setCanSendPromotions] = useState(true);
  const [customerName, setCustomerName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [orderId, setOrderId] = useState('');
  const [orderNotFound, setOrderNotFound] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [warrantyDetails, setWarrantyDetails] = useState(null);

  const formItemLayout = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 12 },
    xl: { span: 12 },
  };

  const warrantyTable = [
    {
      title: 'Product name',
      dataIndex: 'productName',
    },
    {
      title: 'Warranty valid till',
      dataIndex: 'warrantyDate',
    },
  ];

  const validationMessages = {
    required: '${label} is required',
    types: {
      email: 'Please enter a valid email!',
    },
  };

  const toggleSendPromotions = () => {
    setCanSendPromotions((prevState) => !prevState);
  };

  const handleSubmit = async () => {
    setOrderNotFound(false);
    try {
      const payload = {
        customerName,
        email,
        orderId,
        canSendPromotions,
      };
      const csrfToken = CSRFToken(document.cookie);
      const axiosConfig = {
        headers: {
          'X-CSRFToken': csrfToken,
        },
      };
      const url = '/core/api/register-product';
      const response = await axios.post(url, payload, axiosConfig);
      const { status, data } = response;
      if (status === 201 && data.success) {
        message.success('Your product has been registered successfully');
        setRegistrationSuccess(true);
        if (data.warranty) {
          setWarrantyDetails(data.warranty);
        }
      }
    } catch (err) {
      if (err?.response?.status === 400) {
        const {
          response: {
            data: { errors },
          },
        } = err;
        if (errors === 'No order found') {
          setOrderNotFound(true);
        } else if (errors === 'The product is already registered.') {
          message.info('This product is already registered.');
        } else if (errors === 'Not eligible for warranty') {
          message.error('Your product is not eligible for warranty');
        }
      }
    }
  };

  return (
    <div className="product-registration">
      <div className="product-registration__container">
        <div className="product-registration__container__heading">
          <img
            className="product-registration__container__logo"
            src={Logo}
            alt="logo"
          />
          <Typography.Title>
            {registrationSuccess
              ? 'Registration success'
              : 'Product Registration Form'}
          </Typography.Title>
          {!registrationSuccess && (
            <Typography.Text>
              Register your products on the form below.
            </Typography.Text>
          )}
        </div>
        <div className="product-registration__container__form">
          {registrationSuccess ? (
            <div className="product-registration__container__success-container">
              {/* <h1>Registration success</h1> */}
              {warrantyDetails && (
                <Table
                  columns={warrantyTable}
                  dataSource={warrantyDetails}
                  size="small"
                  pagination={false}
                />
              )}
            </div>
          ) : (
            <>
              <Form layout="vertical" validateMessages={validationMessages}>
                <Row gutter={[24, 0]}>
                  <Col {...formItemLayout}>
                    <Form.Item
                      label="Name"
                      name="customerName"
                      rules={[{ required: true }]}
                    >
                      <Input
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        placeholder="Your name"
                      />
                    </Form.Item>
                  </Col>
                  <Col {...formItemLayout}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[{ required: true, type: 'email' }]}
                    >
                      <Input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Your email"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 0]}>
                  <Col {...formItemLayout}>
                    <Form.Item label="Phone no" name="phoneNumber">
                      <Input
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="Your Phone number"
                      />
                    </Form.Item>
                  </Col>
                  <Col {...formItemLayout}>
                    <Form.Item
                      tooltip="The order ID of Amazon or Flipkart"
                      label="Order ID"
                      name="orderId"
                      {...(orderNotFound && {
                        help: orderNotFound
                          ? 'No order found corresponding to this Order ID'
                          : '',
                        validateStatus: orderNotFound ? 'error' : 'validating',
                      })}
                      rules={[{ required: true }]}
                    >
                      <Input
                        value={orderId}
                        onChange={(e) => setOrderId(e.target.value)}
                        placeholder="Your Order ID"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 0]}>
                  <Col {...formItemLayout}>
                    <Form.Item>
                      <Checkbox
                        checked={canSendPromotions}
                        onChange={toggleSendPromotions}
                      >
                        Opt in for promotional Emails
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <div className="product-registration__container__form--submit">
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </>
          )}
          <br />
          <Typography.Text>
            For warranty claims connect with us at -{' '}
            <a href="tel:9289582447">9289 582 447</a>
          </Typography.Text>
          <br />
          <Typography.Text>
            You can also write us at{' '}
            <a href="mailto:mks.enterprises.ecom@gmail.com">
              mks.enterprises.ecom@gmail.com
            </a>
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};
