import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AppContainer } from './components/Layout/AppContainer';
import { ProductTable } from './components/Products/ProductTable';
import { AddOrder } from './components/Orders/AddOrders';
import { CheckWarranty } from './components/Warranty/CheckWarranty';
import { PDFTools } from './components/PDFTools/PDFTools';
import { ProductRegistration } from './components/ProductRegistration/ProductRegistration';

export const SIDEBAR_OPTIONS = {
  DASHBOARD: 'dashboard',
  ORDERS: 'orders',
  PRODUCTS: 'products',
};

export default function App() {
  const [selectedOption, setSelectedOption] = useState('orders');

  const renderContent = () => {
    // switch (selectedOption) {
    //   case SIDEBAR_OPTIONS.ORDERS:
    //     return <Orders />
    //   case SIDEBAR_OPTIONS.PRODUCTS:
    //     return <ProductTable />
    //   default:
    //     <Orders />
    //     // Here dashboard will come later
    // }
  };

  return (
    <Router>
      <AppContainer>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/products" element={<ProductTable />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/pdf-tools" element={<PDFTools />} />
          <Route path="/check-warranty" element={<CheckWarranty />} />
          <Route
            path="/product-registration"
            element={<ProductRegistration />}
          />
        </Routes>
      </AppContainer>
    </Router>
  );
}

function Home() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: '80vh',
      }}
    >
      <h1 style={{ fontWeight: 400, fontSize: '2.5rem' }}>
        We're not live yet...
      </h1>
      Visit <a href="/product-registration">here</a> to register your products
    </div>
  );
}

function Products() {
  return <h2>Products</h2>;
}

function Orders() {
  return <AddOrder />;
}
