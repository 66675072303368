import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import axios from 'axios'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text) => <a><p>{text}</p></a>,
    ellipsis: true,
  },
  {
    title: 'SKU',
    dataIndex: 'sku',
  },
  {
    title: 'Colour',
    dataIndex: 'color',
  },
  {
    title: 'Category',
    dataIndex: 'category',
  },
  {
    title: 'Stock quantity',
    dataIndex: 'stock_quantity',
    sorter: (a, b) => a.stock - b.stock,
  },
];



export const ProductTable = () => {

  const [isLoading, setIsLoading] = useState(true)
  const [productsList, setProductsList] = useState([])

  const fetchProducts = async () => {
    try {
      const response = await axios.get('http://139.59.30.243/core/api/products/')
      if (response.status === 200) {
        setProductsList(response.data)
        setIsLoading(false)
      }
    } catch (e) {
      console.log(e)
    }
  }
  
  useEffect(() => {
    fetchProducts()
  }, [])

  return (
    <div>
      <h2>Product catalogs</h2>
      <Table
        style={{
          width: '100%'          
        }}
        loading={isLoading}
        columns={columns}
        dataSource={productsList}
      />
    </div>
  );
};
