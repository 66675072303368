import React, { useEffect, useState } from 'react';

import { Button, Modal, Select, Divider, InputNumber } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';

import './Orders.scss';

const ListingField = ({ isMarketplaceSelected, listings, counter, handleAddItem }) => {
    const [selectedListing, setSelectedListing] = useState(null)

    const handleListingSelect = (value) => {
        setSelectedListing(value)
        handleAddItem(value, counter)
    }

    const handleItemNum = (e) => {
        console.log(e, selectedListing)
    }

    return (
        <div className="add-order__modal__listing-container">
          {/* <span>{counter}</span> */}
          <div className="add-order__modal__listing">
            <Select
              style={{ overflowX: 'auto' }}
              disabled={!isMarketplaceSelected}
              placeholder="Select Listing"
              options={listings}
              onChange={handleListingSelect}
            />
          </div>
          <InputNumber min={1} defaultValue={1} onChange={handleItemNum} />
        </div>
      )
};

export const AddOrder = () => {
  const [showModal, setShowModal] = useState(false);
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [selectedMarketplace, setSelectedMarketplace] = useState(null);
  const [listings, setListings] = useState([]);
  const [orderItemsCounter, setOrderItemsCounter] = useState(1);
  const [orderItems, setOrderItems] = useState({});

  const toggleShowModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const handleSelectMarketplace = async (value) => {
    setSelectedMarketplace(value);
    await fetchListings(value);
  };

  const handleCounter = () => {
    setOrderItemsCounter((prevState) => prevState + 1);
  };

  const fetchMarketplaceList = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/core/api/marketplaces/`;
    try {
      const response = await axios.get(url);
      if (response.status === 200) {
        const { data } = response;
        const marketplaces = data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setMarketplaceList(marketplaces);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchListings = async (marketplaceId) => {
    const url = `${process.env.REACT_APP_BASE_URL}/core/api/listings/${marketplaceId}`;
    try {
      const response = await axios.get(url);
      console.log(response);
      if (response.status === 200) {
        const { data } = response;
        const _listings = data.map((item) => ({
          value: item.id,
          label: item.product_name,
        }));
        setListings(_listings);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchMarketplaceList();
  }, []);

  const handleAddItem = (value, counter) => {
    console.log(value, counter)
    // let obj
    // setOrderItems(prevState => {
    //     if (prevState[counter]) {
    //         let currentSelection = prevState[counter]
    //         obj = {[value]: currentSelection[value]}
    //     } else {
    //         obj = {[value]: 1}
    //     }
    //     let newState = prevState
    //     newState[counter] = obj
    //     return newState
    // })
    setOrderItems(prevState => {
        let obj = {[value]: 1}
        prevState[counter] = obj
        return prevState
    })
  };

  const handleItemNum = (value) => {

  }

  console.log(orderItems);

  return (
    <div className="add-order__container">
      <div className="add-order__btn-container">
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={toggleShowModal}
        >
          Add Order
        </Button>
      </div>
      <Modal title="Add orders" onCancel={toggleShowModal} open={showModal}>
        <div className="add-order__modal">
          <Select
            placeholder="Select Marketplace"
            onChange={handleSelectMarketplace}
            options={marketplaceList}
          />
          <Divider />
          {Array.from(Array(orderItemsCounter)).map((c, index) => (
            <ListingField
              key={c}
              counter={index + 1}
              isMarketplaceSelected={selectedMarketplace}
              listings={listings}
              handleAddItem={handleAddItem}
            />
          ))}

          <div className="add-order__modal__add-more">
            <Button
              icon={<PlusOutlined />}
              disabled={!selectedMarketplace}
              type="primary"
              onClick={handleCounter}
            ></Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
