import React from 'react'

import './Warranty.scss'

export const CheckWarranty = () => {
    return (
        <div className='warranty__container'>
            <h1 className='warranty__heading'>Check your warranty</h1>
            <div className='warranty__marketplace'>
                <h2 className='warranty__marketplace--heading'>Select a marketplace</h2>
                <div className='warranty__marketplace--container'>
                    <div className='warranty__marketplace--item'>Flipkart</div>
                    <div className='warranty__marketplace--item'>Amazon</div>
                </div>
            </div>
        </div>
    )
}