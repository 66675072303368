import React, { useRef, useState } from 'react';

import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';

import './PDFTools.scss';

export const PDFTools = () => {
  const getInputKey = () => Math.random().toString(36);

  const inputRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [inputKey, setInputKey] = useState(getInputKey());
  const [fileLink, setFileLink] = useState('');

  const handleSelectFilesClick = () => {
    inputRef?.current?.click();
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setInputKey(getInputKey());
  };

  const handleButtonClick = () => {
    if (selectedFile) {
      handleFileUpload();
    } else {
      handleSelectFilesClick();
    }
  };

  const handleFileUpload = async () => {
    const formData = new FormData();
    formData.append('upload_file', selectedFile);
    formData.append('action', 'split');

    try {
      const url = '/core/api/pdf-tools';
      const response = await axios.post(url, formData);
      if (response.status == 200) {
        const {
          data: { filename },
        } = response;
        const link = `/core/api/download-file/${filename}`;
        setFileLink(link);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <h1>PDF Tools</h1>
      <div className="pdf-tools">
        <div className="pdf-tools__tools-section">
          <div className="pdf-tools__actions-container">
            <div className="pdf-tools__action">
              <h3>Split shipping labels</h3>
            </div>
            <div className="pdf-tools__action">
              <h3>Merge PDFs</h3>
            </div>
            <div className="pdf-tools__action">
              <h3>Merge PDFs & Split shipping labels</h3>
            </div>
          </div>
        </div>
        <div className="pdf-tools__upload-section">
          <div className="pdf-tools__upload-section__container">
            <h2>Upload files</h2>
            {fileLink ? (
              <Button
                className="pdf-tools__upload-section__download-btn"
                type="link"
                href={fileLink}
              >
                Download
              </Button>
            ) : (
              <Button type="primary" onClick={handleButtonClick}>
                {selectedFile ? 'Upload' : 'Select file'}
              </Button>
            )}

            {selectedFile && !fileLink && (
              <div className="pdf-tools__upload-section__file-name-container">
                <p className="pdf-tools__upload-section__file-name">
                  {selectedFile.name}
                </p>
                <DeleteOutlined
                  style={{ fontSize: '16px', cursor: 'pointer' }}
                  onClick={handleRemoveFile}
                />
              </div>
            )}
            <input
              className="pdf-tools__upload-section__file-input"
              type="file"
              name="upload_file"
              accept="application/pdf"
              ref={inputRef}
              key={inputKey}
              onChange={handleFileChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};
