import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DesktopOutlined, PieChartOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme } from 'antd';

import { Link } from 'react-router-dom';

import './AppContainer.css';

const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem('Dashboard', '1', <PieChartOutlined />),
  getItem('Products', '2', <DesktopOutlined />),
  getItem('Orders', '3', <DesktopOutlined />),
  getItem('Orders Dashboard', '3', <DesktopOutlined />),
];

const navLinks = [
  {
    key: '1',
    label: <Link to="/">Dashboard</Link>,
    icon: <PieChartOutlined />,
  },
  {
    key: '2',
    label: <Link to="/products">Products</Link>,
    icon: <DesktopOutlined />,
  },
  {
    key: '3',
    label: <Link to="/orders">Orders</Link>,
    icon: <DesktopOutlined />,
  },
  {
    key: '4',
    label: <Link to="/orders-dashboard">Orders Dashboard</Link>,
    icon: <DesktopOutlined />,
  },
];

const EXEMPTED_ROUTES = ['/check-warranty', '/product-registration', '/'];

export const AppContainer = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const { pathname } = useLocation();

  const isContainerExemptedRoute = EXEMPTED_ROUTES.includes(pathname);

  return (
    <>
      {isContainerExemptedRoute ? (
        <>{children}</>
      ) : (
        <Layout
          style={{
            minHeight: '100vh',
          }}
        >
          <Sider
            // style={{
            //   overflow: 'auto',
            //   height: '100vh',
            //   position: 'fixed',
            //   left: 0,
            //   top: 0,
            //   bottom: 0,
            // }}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
          >
            <div className="app_container__icon">
              <h1 className="app_container__icon--text">HYPERON</h1>
            </div>
            <Menu
              theme="dark"
              defaultSelectedKeys={['1']}
              mode="inline"
              items={navLinks}
            />
          </Sider>
          <Layout>
            <Header
              style={{
                padding: 0,
                background: colorBgContainer,
              }}
            />
            <Content
              style={{
                margin: '0 16px',
              }}
            >
              {children}
              {/* <Breadcrumb
            style={{
              margin: '16px 0',
            }}
          >
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Pro</Breadcrumb.Item>
          </Breadcrumb>
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            Bill is a cat.
          </div> */}
            </Content>
            <Footer
              style={{
                textAlign: 'center',
              }}
            >
              MKS Enterprises ©{new Date().getFullYear()} Created by MKS
              Enterprises
            </Footer>
          </Layout>
        </Layout>
      )}
    </>
  );
};
export default AppContainer;
